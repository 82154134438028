import { Box, Typography } from '@mui/material'
import FAQ from '@talentinc/gatsby-theme-ecom/components/FAQ'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useBrand from '../../hooks/useBrand'
import { Brand } from '@talentinc/gatsby-theme-ecom/types/brand'

// @TODO: use dynamic lowest price for resume packages offer
function getLowestPrice(brand: Partial<Brand>) {
  if (brand.isTopResume) return '$169'
  if (brand.isTopCV) return '129€'
  if (brand.isTopCVuk) return '69£'
  if (brand.isTopCvDe) return '129€'
  if (brand.isTopCvFr) return '149€'
}

function HomeFAQ() {
  const { t } = useTranslation()
  const brand = useBrand()
  const { flagshipProduct, display_name: brandName } = brand

  const items = t('home.faq.items', {
    returnObjects: true,
    defaultValue: [],
    flagshipProduct,
    brandName,
    lowestPrice: getLowestPrice(brand),
  }) as { question: string; answer: string }[]

  return (
    <div>
      <Typography variant="h2">{t('home.faq.title')}</Typography>
      {items.map(({ question, answer }, index) => {
        const id = `${index + 1}`

        return <FAQ contentful_id={id} question={question} answer={answer} />
      })}
    </div>
  )
}

export default HomeFAQ
